<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12>
      <v-card>
        <titleCard title="Suscripciones" subtitle="Listado de suscripciones del cliente"></titleCard>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="info"
          class="elevation-0 order-table"
          color="secondary"
          :disable-initial-sort="true"
          :pagination.sync="pagination"
          :rows-per-page-items="rowsPerPage"
          no-data-text="No esta suscrito"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>
                {{ props.item.id }}
              </td>
              <td>{{ props.item.plan.name }} - {{ props.item.plan.type }}</td>
              <td>
                {{ props.item.total | toPrice }}
              </td>
              <td>
                <template v-for="(order, e) in props.item.orders">
                  <router-link
                    :key="e"
                    class="white--text"
                    target="_blank"
                    style="text-decoration:none"
                    :to="{ name: 'orderIndex', params: { id: order.id } }"
                  >
                    <v-chip label color="info" text-color="white" small>
                      {{ order.id }} - {{ order.date | moment('DD MMMM YYYY') }} </v-chip
                    ><br />
                  </router-link>
                </template>
                <span v-if="props.item.orders.length === 0">
                  -
                </span>
              </td>
              <td>
                <v-chip :key="e" label :color="colorStatus(props.item.status)" text-color="white" small>
                  <span v-if="!props.item.status">Pendiente</span>
                  <span v-if="props.item.status === 1">Pagado en curso</span>
                  <span v-if="props.item.status === 2">Pagado finalizado</span>
                </v-chip>
              </td>
              <td>
                <v-btn color="info" flat small @click="openDialog(props.item)">
                  detalle
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
    <v-dialog v-model="dialog" scrollable max-width="400">
      <v-card v-if="suscription">
        <titleDialog
          :title="`Suscripcion Nº${suscription.id}`"
          :subtitle="`Ingresado el dia ${fullDate(suscription.date_in)} hrs`"
          :close-action="
            () => {
              dialog = false
            }
          "
          close
        >
        </titleDialog>
        <v-layout wrap>
          <v-flex xs12 class="pa-0">
            <v-subheader>Producto</v-subheader>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs12 class="px-3 pt-3">
            {{ suscription.product.name }}
          </v-flex>
          <v-flex xs12 class="pa-0">
            <v-subheader>Datos de entrega</v-subheader>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs12 class="px-3 pt-3">
            <p>
              {{ suscription.shipping.name }}<br />
              {{ suscription.shipping.street }} {{ suscription.shipping.street_number }}<br />
              {{ suscription.shipping.depto }}<br />
              {{ suscription.shipping.comuna }}
            </p>
          </v-flex>
          <template v-if="suscription.orders.length > 0">
            <v-flex xs12 class="pa-0">
              <v-subheader>Pedidos generados</v-subheader>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs12 class="px-3 pt-3">
              <template v-for="(order, e) in suscription.orders">
                <router-link
                  :key="e"
                  class="white--text"
                  target="_blank"
                  style="text-decoration:none"
                  :to="{ name: 'orderIndex', params: { id: order.id } }"
                >
                  <v-chip label color="info" text-color="white" small>
                    {{ order.id }} - {{ order.date | moment('DD MMMM YYYY') }} </v-chip
                  ><br />
                </router-link>
              </template>
            </v-flex>
          </template>
          <v-flex xs12 class="pa-0">
            <v-subheader>Forma de pago</v-subheader>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs12 class="px-3 pt-3">
            <p>
              Forma de pago: {{ suscription.payment.name }} <br />
              Total: {{ suscription.total | toPrice }} <br />
              <span v-if="suscription.date_pay">
                Fecha de pago: {{ suscription.date_pay | moment('DD-MM-YYYY HH:mm:ss') }}
              </span>
            </p>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { CLIENT } from '../../config'
import titleCard from '../useful/titleCard.vue'
import titleDialog from '../useful/titleDialog.vue'

export default {
  name: 'BuyerSuscriptions',
  components: { titleCard, titleDialog },
  props: ['buyer'],
  data: () => ({
    headers: [
      { text: '#', align: 'left', sortable: false },
      { text: 'Plan', align: 'left', sortable: false },
      { text: 'Total', align: 'left', sortable: false },
      { text: 'Pedidos', align: 'left', sortable: false },
      { text: 'Estado', align: 'left', sortable: false },
      { text: '', align: 'left', sortable: false }
    ],
    pagination: { rowsPerPage: 50 },
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
    info: [],
    dialog: false,
    suscription: null
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  mounted() {
    this.getOrders()
  },
  methods: {
    async getOrders() {
      this.loading = true
      try {
        const res = await this.$http.get(`${CLIENT}/${this.buyer.id}/suscriptions`)
        this.info = res.data
        this.loading = false
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    colorStatus(e) {
      if (!e) {
        return 'warning'
      } else if (e === 1) {
        return 'success'
      }
      return 'success'
    },
    openDialog(item) {
      this.dialog = !this.dialog
      this.suscription = item
    },
    fullDate(date) {
      return this.$moment(date).format('DD-MM-YYYY HH:mm:ss')
    }
  }
}
</script>
